import React from "react";
import { Spinner } from "react-bootstrap";
import { ServiceSection } from "./Service/Service.elements2";
import style from './Loading.module.css';
const Loading=()=>{
    return(
 <div className={style.container}>
       <div className={`d-flex justify-content-center bg-light align-middle ${style.main}`} >
    <Spinner animation="grow" variant="primary" className='m-2 '  />
    <Spinner animation="grow" variant="primary"  className='m-2'  />
    <Spinner animation="grow" variant="primary"   className='m-2' />
    </div>
 </div>
  
    )
}
export default Loading;