import React, {  useEffect, useRef, useState } from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from "@emailjs/browser";
import { ArrowUpShort } from 'react-bootstrap-icons';
import { Route, Routes } from 'react-router-dom' 
import {  Footer } from './components';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Landing from './Landing';
import NavBar from './components/Navbar/Navbar';
import { HelmetProvider } from 'react-helmet-async';
import ServiceDeatils from './pages/servicesDetail/ServiceDeatils';
import Loading from './components/Loading';
const config=require('../src/config');

function App() {
  const[show,setShow]=useState(false);
 // const[trigger,setTrigger]=useState(false)
/* const refs={
  ServiceRef:useRef(null),
  HomeRef:useRef(null),
  ProductRef:useRef(null),
  ContactRef:useRef(null)
}
 */
const loadRef=useRef();
 useEffect(()=>{
emailjs.init(config.REACT_APP_PUPLIC_KEy);
  const mobileNavShow = document.querySelector('.mobile-nav-show');
  const mobileNavHide = document.querySelector('.mobile-nav-hide');

  document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
    el.addEventListener('click', function(event) {
      event.preventDefault();
      mobileNavToogle();
    })
  });

  function mobileNavToogle() {
    document.querySelector('body').classList.toggle('mobile-nav-active');
    mobileNavShow.classList.toggle('d-none');
    mobileNavHide.classList.toggle('d-none');
  }

  /**
   * Toggle mobile nav dropdowns
   */
  const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');

  navDropdowns.forEach(el => {
    el.addEventListener('click', function(event) {
      if (document.querySelector('.mobile-nav-active')) {
        event.preventDefault();
        this.classList.toggle('active');
        this.nextElementSibling.classList.toggle('dropdown-active');

        let dropDownIndicator = this.querySelector('.dropdown-indicator');
        dropDownIndicator.classList.toggle('bi-chevron-up');
        dropDownIndicator.classList.toggle('bi-chevron-down');
      }
    })
  });

  /**
   * Scroll top button
   */
  const scrollTop = document.querySelector('.scroll-top');
  if (scrollTop) {
    const togglescrollTop = function() {
      window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
    }
    window.addEventListener('load', togglescrollTop);
    document.addEventListener('scroll', togglescrollTop);
    scrollTop.addEventListener('click', window.scrollTo({
      top: 0,
      behavior: 'smooth'
    }));
  }

  AOS.init({
    duration: 800,
    easing: 'slide',
    once: true,
    mirror: false
  }
    
  );

 },[]) 
 useEffect(()=>{
  const selectHeader = document.querySelector('#header');
  if (selectHeader) {
    document.addEventListener('scroll', () => {
      window.scrollY > 100 ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
    });
  }
 
  const timer = setTimeout(() => {
    console.log('This will run after 1 second!')
    setShow(false);
  }, 3000);
  return () => clearTimeout(timer);

 },[])
 

const showLoading=()=>{
//setShow(true);
}
/*   const ScrollToSec=(ref)=>{
     window.scrollTo({
      top:loadRef.current.offsetTop,
      behavior:'smooth'
    }) 
  } */
  return (
    
    
    <HelmetProvider>
  
  {
    show? <div ref={loadRef}><Loading/></div>: (
      <div >
 
      <NavBar  />

      
<Routes>

  <Route path='/'   element={<Landing/>}/>
  
  <Route index  element={<Landing/>}/>
  <Route path='/servicedeatils'    element={<ServiceDeatils onShow={showLoading}/>}/>
  <Route path="*" element={<p>There's nothing here: 404!</p>} />
    
</Routes>

        
         <a href="#" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"><ArrowUpShort/></i></a>
         <Footer />
         </div>
    )
  }


  </HelmetProvider>
      
    
  );
}

export default App;
