import React from 'react';
import Product from './Product';

function Products() {
  return (
    <>
   <Product/>
    </>
  );
}

export default Products;