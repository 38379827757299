import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Dash, Twitter } from 'react-bootstrap-icons';
import Logo from '../../images/logo3.png';
import {
  FaFacebook,
} from 'react-icons/fa';

function Footer() {

  const date = new Date().getFullYear();


 return(
<>

<footer id="footer" class="footer">

<div class="footer-content">
  <div class="container">
    <div class="row gy-4">
      <div class="col-lg-5 col-md-12 footer-info">
        <HashLink style={{ textDecoration: 'none' }}  data-to-scrollspy-id="home"  smooth to ="/#home" class="logo d-flex align-items-center">
          <span> <img src={Logo} loading="lazy" alt='4cSolutions'/> Solutions</span>
        </HashLink>
        <p>We are registered business group in Djibouti which offers a myriad of products and services to our clients ranging from: consulting, commerce, coaching and communication. Our services and products will be extended to our clients with the highest level of professionalism and trending market needs. 
</p>
        <div class="social-links d-flex  mt-3">
           <HashLink to="#" class="twitter"><i class="bi bi-twitter"></i><Twitter/> </HashLink>
           <HashLink to="#" class="facebook"><i class="bi bi-facebook"><FaFacebook/></i> </HashLink>
           
        </div>
      </div>

      <div class="col-lg-2 col-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><i class="bi bi-dash"><Dash/></i> <HashLink style={{'textDecoration':'none'}}  to="/#home">Home</HashLink></li>
          <li><i class="bi bi-dash"><Dash/></i>  <HashLink style={{'textDecoration':'none'}} to="#">About us </HashLink></li>
          <li><i class="bi bi-dash"><Dash/></i>  <HashLink style={{'textDecoration':'none'}}  to="/#services">Services </HashLink></li>
          <li><i class="bi bi-dash"><Dash/></i>  <HashLink style={{'textDecoration':'none'}} to="/#products">Products </HashLink></li>
          <li><i class="bi bi-dash"></i><Dash/>  <HashLink style={{'textDecoration':'none'}} to="/#contact">Contact </HashLink></li>
        </ul>
      </div>

    
      <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
        <h4>Contact Us</h4>
        <p>

        Cite Gachamalleh, <br/>
        Rue d'arta,<br/>
                    Commune Boulaos,
                    Djibouti <br/>
          <strong>Phone:</strong> (+253) 77598136 / 77292993 / 77272773<br/>
          <strong>Email:</strong> info@4csolution.com<br/>
        </p>

      </div>

    </div>
  </div>
</div>
   <div className="footer-legal">
      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong> <span> <img src={Logo} height='40rem' loading="lazy"/> Solutions</span></strong>. All Rights Reserved {date}
        </div>
      {/*   <div className="credits">
         
        <p className='blockquote-footer mt-1'> Designed by  <HashLink  onClick={()=>{  window.open(
         'https://www.linkedin.com/in/ghassan-ghanem-a457a315b/', "_blank"); }}>   <strong> Ghassan A. Ghanem</strong> </HashLink></p>
        </div> */}
      </div>
    </div>
</footer>
</>

)
}

export default Footer;