import React from 'react';
import IMG1 from '../../images/consultingFlat.jpg';
import { CheckCircle } from "react-bootstrap-icons";

const Consulting=()=>{
    return(
        <section  data-aos="fade-down-right" data-aos-delay="300">
        <img src={IMG1} alt="" loading="lazy" class="img-fluid services-img"/>
       <h3> Voulez-vous des renseignements sur le marché djiboutien? <br/> Do you need information on the Djiboutian market? 
       <br/> هل تريد الإطلاع على السوق الجيبوتي؟</h3>
       <p>
         Thanks to our sterling track record in the Djiboutian business market,we are the solution to all the problems new businesses will potentially face entering said market. We offer consulting to: 
       </p>
       <ul>
         <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>NGOs(Non-Governmental Organisations)</span></li>
         <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Businesses already established in Djibouti.</span></li>
         <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Public sector.</span></li>
       </ul>
      
   </section>
    )
}
export default Consulting;