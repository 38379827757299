import React from 'react';
import IMG1 from '../../images/CommFlat.jpg';
import { CheckCircle } from "react-bootstrap-icons";
const Commerce=()=>{
    return(
        <section data-aos="fade-down-right" data-aos-delay="300">
             <img src={IMG1} alt="" loading="lazy" class="img-fluid services-img"/>
            <h3>  Partnering, selling and commercialising.</h3>
            <p>
            Our commerce offering is centered on a dual marketing focus which is:
            </p>
            <ul>
              <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Sale of services for companies.</span></li>
              <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Commercialising of products for businesses. </span></li>

            </ul>
          
        </section>
    )
}
export default Commerce;