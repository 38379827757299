import React, { useEffect, useRef, useState } from "react";
import { ArrowUpShort } from 'react-bootstrap-icons';
import ScrollToTop from "../../components/ScrollToTop";

import { HashLink } from "react-router-hash-link";
import Communication from "../../components/Service/Communication";
import Commerce from "../../components/Service/Commerce";
import Coashing from "../../components/Service/Coashing";
import Consulting from "../../components/Service/Consulting";
import ContactUs from "../ContactUS/ContactUs";
import { Helmet } from "react-helmet-async";

const COMMUNICATION="Communication";
const COASHING="Coaching";
const COMMERCE="Commerce";
const CONSULTING="Consulting";
const DEFAULTACTIVE={
  commerce:false,
  communication:false,
  consulting:false,
  coashing:false,
  

}
const ServiceDeatils = (props) => {
  const[ActiveComponent,setActiveComponent]=useState(DEFAULTACTIVE);
  const[selectedComponent,setSelectedComponent]=useState(<Communication/>);
  const [show,setShow]=useState(true);
const detailRef=useRef();
  useEffect(()=>{
setActiveComponent({...DEFAULTACTIVE,communication:true})
//props.onShow();
  },[])
  const ScrollToSec=(ref)=>{
        window.scrollTo({
          bottom:detailRef.current.offsetTop,
          behavior:'smooth'
        }) 
      }

  return (
    <>
    <Helmet>
     <title>Our Services</title>
       <meta name='description' content='Our top-of-the-line 4C service in Djibouti includes a full Consultation, Communication, Commerce, and Coashing' />
       </Helmet>
      <ScrollToTop/>
      <HashLink to="/#" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"><ArrowUpShort/></i></HashLink>

      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Service Details</h2>
                <p>
                No matter what your goals, we are here to help you achieve them. Contact us today to learn more about our services and how we can help you reach your full potential.                </p>
              </div>
            </div>
          </div>
        </div>
        <nav>
        <div className="container">
          <ol>
          
            <li><HashLink to="/#">Home</HashLink></li>
            <li>Service Details</li>
          </ol>
        </div>
      </nav>
      </div>
      <section className="service-detailsv2">
      <div className="container" data-aos="fade-up">
      <div className="row gy-4">
      <div className="col-lg-4">
            <div className="services-listv2">
              <HashLink style={{'textDecoration':'none'}} onClick={()=>{   setSelectedComponent(<Communication/>); ScrollToSec(); setActiveComponent({...DEFAULTACTIVE,communication:true})}} className={ActiveComponent.communication ?"active":""} >{COMMUNICATION}</HashLink>
              <HashLink style={{'textDecoration':'none'}} onClick={()=>{setSelectedComponent(<Coashing/> ); setActiveComponent({...DEFAULTACTIVE,coashing:true}) }} className={ActiveComponent.coashing ?"active":""}   >{COASHING}</HashLink>
              <HashLink style={{'textDecoration':'none'}} onClick={()=>{   setSelectedComponent(<Commerce/>); setActiveComponent({...DEFAULTACTIVE,commerce:true})}}  className={ActiveComponent.commerce ?"active":""}>{COMMERCE}</HashLink>
              <HashLink style={{'textDecoration':'none'}} onClick={()=>{   setSelectedComponent(<Consulting/>); setActiveComponent({...DEFAULTACTIVE,consulting:true})}} className={ActiveComponent.consulting ?"active":""} >{CONSULTING}</HashLink>

            </div>
            
            <h4>4C Solutions</h4>
            <p>Our top-of-the-line 4C service includes a full Consultation, Communication, Commerce, and Coashing </p>

      </div>
      <div class="col-lg-8" id="section" >
      <div ref={detailRef}>
      {selectedComponent  }
      </div>
    
      </div>
      </div>
  <ContactUs/>    
      </div>
      </section>
    </>
  );
};
export default ServiceDeatils;
