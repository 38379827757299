import React  from 'react';

import Service from '../../components/Service/Service';


function Services() {



 const handlNavigate=(link)=>{
  console.log("lolol")
    window.open(
    link, "_blank");


  }
  return (
    <>
      <Service onClick={handlNavigate} />
    
    
    </>
  );
}

export default Services;