const REACT_APP_API_URL="http://localhost:3000"
const REACT_APP_SERVICE_ID="service_9waifzi"
const REACT_APP_Template_ID="template_uri83s1"
const REACT_APP_PUPLIC_KEY="kHLOmeaRJzlb0Yuw1"

module.exports={
    REACT_APP_API_URL:REACT_APP_API_URL,
    REACT_APP_SERVICE_ID:REACT_APP_SERVICE_ID,
    REACT_APP_Template_ID:REACT_APP_Template_ID,
    REACT_APP_PUPLIC_KEY:REACT_APP_PUPLIC_KEY
}