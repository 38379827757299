import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./infoSection.module.css";

const InfoSection = ({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
}) => {
  return (
    <>
      <div className={`${styles.infoSec} d-flex align-items-center`} data-aos="fade-up" data-aos-delay="100">
        <Container className={`${styles.container}`}>
          <Row>
            <Col xl={4}>
              <h2 data-aos="fade-up">Future Home of Something Quite Cool</h2>
              <blockquote data-aos="fade-up" data-aos-delay="100">
                <p>
       We are registered business group in Djibouti which offers a myriad of products and services to our clients ranging from: consulting, commerce, coaching and communication. Our services and products will be extended to our clients with the highest level of professionalism and trending market needs. 
                </p>
              </blockquote>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default InfoSection;
