export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Solutions Company',
    headline: 'Future Home of Something Quite Cool',
    description:
      'We help business owners increase their revenue. Our team of unique specialist can help you achieve your business goals.',
    buttonLabel: 'Contact Now',
    imgStart: '',
    img: require('../../images/futuristic.png'),
    alt: 'Credit Card',
    start: ''
  };
  
