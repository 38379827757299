import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import LogoImg from "../../images/logo3.png";
import { Outlet } from "react-router-dom";
import { List, X } from "react-bootstrap-icons";
function NavBar(props) {
  const [click, setClick] = useState(false);
  //const [button, setButton] = useState(true);

  /*     const [homeClick, setHomeClick] = useState(false);
    const [servicesClick, setServicesClick] = useState(false);
    const [productsClick, setProductsClick] = useState(false); */

  const handleHomeClick = () => {
    //props.goTo("HomeRef");
    /*   setHomeClick(true);
        setProductsClick(false);
        setServicesClick(false); */
  };
  const handleServicesClick = () => {
    // props.goTo("ServiceRef")
    /*  setHomeClick(false);
        setProductsClick(false);
        setServicesClick(true); */
  };
  const handleProductsClick = () => {
    //   props.goTo("ProductRef")
    /*      setHomeClick(false);
        setProductsClick(true);
        setServicesClick(false); */
  };
  const handleContactClick = () => {
    //  props.goTo("ContactRef")
    /*  setHomeClick(false);
        setProductsClick(true);
        setServicesClick(false); */
  };

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    // so if the screensize is <= 960px then set button state to false
    if (window.innerwidth <= 960) {
      // setButton(false)
    } else {
      // setButton(true)
    }
  };

  window.addEventListener("resize", showButton);

  return (
    <>
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between ">
          <HashLink
            style={{ textDecoration: "none" }}
            data-to-scrollspy-id="home"
            className="logo d-flex align-items-center"
            to="/#home"
            smooth
          >
            <img src={LogoImg} height="90px" loading="lazy" />

            <h1 className="d-flex align-items-center">SOLUTIONS</h1>
          </HashLink>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list">
            <List />{" "}
          </i>
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x">
            {" "}
            <X />{" "}
          </i>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <HashLink
                  style={{ textDecoration: "none" }}
                  to="/#home"
                  data-to-scrollspy-id="home"
                  smooth
                  as={HashLink}
                >
                  Home
                </HashLink>
              </li>

              <li>
                <HashLink
                  style={{ textDecoration: "none" }}
                  as={HashLink}
                  to="/#services"
                  smooth
                  data-to-scrollspy-id="services"
                >
                  Serivces
                </HashLink>
              </li>

              <li>
                <HashLink
                  style={{ textDecoration: "none" }}
                  data-to-scrollspy-id="products"
                  as={HashLink}
                  to="/#products"
                  smooth
                >
                  Products
                </HashLink>
              </li>

              <li>
                <HashLink
                  style={{ textDecoration: "none" }}
                  as={HashLink}
                  to="/#contact"
                  data-to-scrollspy-id="contact"
                >
                  Contact Us
                </HashLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <Outlet />
    </>
  );
}

export default NavBar;
