import React from "react";
import { NavHashLink } from "react-router-hash-link";
const PreFooter=()=>{
    return(
        <section id="call-to-action" className="call-to-action">
        <div class="container" data-aos="fade-up">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <h3>4C solutions is the oasis in the stiff marketing business in East Africa. </h3>
              <p>No matter what your goals, we are here to help you achieve them. Contact us today to learn more about our services and products and how we can help you reach your full business potential.</p>
              <NavHashLink style={{ textDecoration: 'none' }}  className="cta-btn" to="/servicedeatils">Check Out Our Serivces</NavHashLink>
              
            </div>
          </div>
  
        </div>
      </section>
    )
}
export default PreFooter;