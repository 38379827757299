
import React from "react";

import { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Form,
  Button,
  Row,
  Spinner,
} from "react-bootstrap";
import emailjs from "@emailjs/browser";

import { Envelope, GeoAlt, Phone } from "react-bootstrap-icons";
const config=require("../../config");
const ContactUs = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [formBorder, setFormBorder] = useState("border ");
  const subjectRef = useRef('');
  const emailRef = useRef('');
  const messageRef = useRef('');
  const nameRef=useRef();
  const formRef = useRef();
  
  const validate=()=> {
    if (emailRef.current.value === '') {
      emailRef.current.setCustomValidity('Cannot be empty');
      return false;
    } else if (nameRef.current.value === '')  {
      nameRef.current.setCustomValidity('Cannot be empty');
      return false;
    }
    else if (messageRef.current.value === '')  {
      messageRef.current.setCustomValidity('Cannot be empty');
      return false;
    }
    else{
      return true;
    }
  
  }
//  const [iSent, setISent] = useState(false);
  const [FeedBack, setFeedBack] = useState("");
//  const [error, setError] = useState(false);
  //console.log("form ref", process.env.REACT_APP_SERVICE_ID);
  useEffect(() => {
    if (isLoading) {
      emailjs
        .sendForm(
          config.REACT_APP_SERVICE_ID,
          config.REACT_APP_Template_ID,
          formRef.current,
          config.REACT_APP_PUPLIC_KEY
        )
        .then(
          (result) => {
            //console.log("success", result.text);
            formRef.current.reset();
            setFeedBack("Submitted Successfully");
            setFormBorder("border border-success");

            setLoading(false);
          },
          (error) => {
            setFeedBack("Something went wrong. Please try agin later");
            console.log("emjserr ", error.text);
            setLoading(false);
            setFormBorder(" border border-danger");
          }
        );
    }
  }, [isLoading]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid=validate();
    console.log(formRef.current,"validation")
  if(valid){

    setLoading(true);
  }
   
    console.log("form ref",config.REACT_APP_SERVICE_ID)
  };
  return (
    
      <section className="contact section-header ">
      <h2 data-aos="fade-down">Contact Us</h2>
        <Container className="container position-relative" data-aos="fade-up">
        
          <Row className="row gy-2 d-flex justify-content-end">
            <Col className="col-lg-5" data-aos="fade-up" data-aos-delay="100">
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0">
                  <GeoAlt />
                </i>
                <div>
                  <h4>Location:</h4>
                  <p className="text-start">
                  Cite Gachamalleh, Rue d'arta, Commune Boulaos, Djibouti Ville, Djibouti
                  </p>
                </div>
              </div>
              <div class="info-item d-flex">
                <i class="bi bi-envelope flex-shrink-0"><Envelope/></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@4csolution.com</p>
                </div>
              </div>

              <div class="info-item d-flex">
                <i class="bi bi-phone flex-shrink-0"> <Phone/></i>
                <div>
                  <h4>Call:</h4>
                  <p>(+253) 77598136 / 77292993 / 77272773</p>
                </div>
              </div>
            </Col>
            <Col lg={6} data-aos="fade-up" data-aos-delay="250">
            <Form ref={formRef} name="4cform" className={`rounded shadow   p-2  ${formBorder} text-start`}>
                  <Form.Group className="mb-3 text-muted" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                    ref={nameRef}
                    required
                      type="text"
                      name="user_name"
                      placeholder=" Your Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email" >
                    <Form.Label className="text-muted">
                      Email address
                    </Form.Label>
                    <Form.Control ref={emailRef}
                    required
                      type="email"
                      name="user_email"
                      placeholder="Enter email"
                    />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3 text-muted" controlId="subject">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                    ref={subjectRef}
                      type="text"
                      name="subject"
                      placeholder="Subject"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-muted">Message</Form.Label>
                    <Form.Control
                    required
                    ref={messageRef}
                      as="textarea"
                      name="message"
                      rows={3}
                      placeholder=" Write your message"
                    />
                  </Form.Group>

                  <div className="d-flex justify-content-end pt-2">
                    <Form.Text className="text-muted p-1">{FeedBack}</Form.Text>
                    <Button
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      <Spinner
                        as="span"
                        className={isLoading ? " " : "visually-hidden"}
                        animation="border"
                        size="sm"
                        aria-hidden="true"
                      />

                      {isLoading ? "Submitting " : "Submit"}
                    </Button>
                  </div>
                </Form>
            </Col>
          </Row>
        </Container>
      </section>
     
 
  );
};
export default ContactUs;
