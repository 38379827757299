import React from 'react';
import { CheckCircle } from "react-bootstrap-icons";
import IMG1 from '../../images/coashingFlat.jpg';

const Coashing=()=>{
    return(
        <section data-aos="fade-down-right" data-aos-delay="300">
        <img src={IMG1} loading="lazy" alt="" class="img-fluid services-img"/>
       <h3> Revolutionising your company's quality output through coaching and training.</h3>
       <p>
       Our coaching services are designed to help individuals and teams to achieve their goals and reach their full potential. Our coaches are trained in various
        coaching methodologies and work with clients to set and achieve goals, develop new skills, and overcome challenges. We offer a variety of coaching programs, which includes:       </p>
       <ul>
         <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Executive coaching.</span></li>
         <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Leadership development.</span></li>
         <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Team building.</span></li>
         <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Life coaching.</span></li>
       </ul>
       
   </section>
    )
}
export default Coashing;