import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import styles from './product.module.css';
import {
  ServiceSection,
  ServiceCard,
} from "../../components/Service/Service.elements2";
import HTimage from '../../images/hornTalent.png';
import HOimag from '../../images/hohat.png';
import Pivot from  '../../images/PIVOT.png';
import HTrainimage from '../../images/horntraining.png';
const hornTalent = "https://www.horntalent.com/";
const hornOverSeas = "http://hornoverseas.com/";
const pivoThrms = "http://pivothrms.com/";

const Product = (props) => {
    const navigateTo=(url)=>{
        console.log("clicked")
         window.open(
         url, "_blank"); 
    }
  return (
    <>
      <section id="services-list" className="services-list bg-white  ">
        <ServiceSection className="container" data-aos="fade-up">
          <div className="section-header">
         
            <Row className=" gx-5 gy-5 bg-white  border-radius-1 ">
            <h2 data-aos="fade-down">Our Products</h2>
              <Col sm={12} lg={3}
                className="  d-flex text-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >

         <ServiceCard className='m-4' onClick={()=>{navigateTo(hornTalent)}}>
         <Card.Body>
           <Card.Img src={HTimage}  />
           </Card.Body>
          </ServiceCard>
              </Col>
        
              <Col
                className="   d-flex text-center"
                data-aos="fade-up"
                data-aos-delay="100"
                sm={12} lg={3}
              >
         <ServiceCard className='m-4' onClick={()=>{navigateTo(hornOverSeas)}}>
<Card.Body>
         <Card.Img src={HOimag} />
         </Card.Body>
        </ServiceCard>
              </Col>
              <Col
                className="   d-flex text-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                  <ServiceCard className='m-4'  onClick={()=>{navigateTo(pivoThrms)}}>
                   <Card.Body>
         <Card.Img src={Pivot}/>
         </Card.Body>
        </ServiceCard>
              </Col>
              <Col
                className=" text-center     d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
                sm={12} lg={3}
              >
        <ServiceCard className='m-4' >
         <Card.Body>
         <Card.Img src={HTrainimage} />
         <h3 className="text-danger ">
          Comming Soon!
         </h3>
         <div className={styles.colorOverlay}>

         </div>
       
        
         </Card.Body>
    
  
    
   
        </ServiceCard>
              </Col>
            </Row>
          </div>
        </ServiceSection>
      </section>
    </>
  );
};

export default Product;
