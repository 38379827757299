import React from 'react';
import { Button } from '../../globalStyles';
import {  ArrowRightCircle } from 'react-bootstrap-icons';
import { Card, Col,Row } from 'react-bootstrap';
import Consulting from '../../images/consulting.jpg';
import Communication from '../../images/Communication.jpg';
import Coashing from '../../images/Coashing.png';
import Commerce from '../../images/e-commerce.jpg';
import { useNavigate } from "react-router-dom";
import {
  ServiceSection,
  ServiceCard
} from './Service.elements2';

 const Service = (props) => {
  const navigate=useNavigate();

  
  return (
    <>

<section id="services-list" className="services-list bg-white  ">
<ServiceSection className=''>
<div className="section-header">
          
<Row className='   border-radius-1'>
<h2  data-aos="fade-down">Our Services</h2>
<Col sm={12} lg={4}  className='service-item d-flex ' data-aos="fade-up" data-aos-delay="400">
<ServiceCard className='m-4 text-left'     >
          
          
          <Card.Body>
          
          <Card.Img src={Communication} text="Consulting"/>
            <Card.Title className='text-center' >
             Communication
           </Card.Title>
          <Card.Text className='text-muted text-left'>
We offer marketing communication strategies which are aimed at revolutionising the business communication sphere. 

       </Card.Text>
      
          </Card.Body>
        
           </ServiceCard>
</Col>
<Col sm={12} lg={4}  className='  service-item   d-flex' data-aos="fade-up" data-aos-delay="400">
<ServiceCard className='m-4'      >
          
           
          <Card.Body>
          <Card.Img src={Coashing} text="Consulting"/>
            <Card.Title className='text-center'>
             Coaching
           </Card.Title>
          <Card.Text className='text-muted'>
       Our coaching services are aimed at bolstering personal development and professional development.
       </Card.Text>
  
          </Card.Body>
        
           </ServiceCard>
</Col>
<Col sm={12} lg={4}   className=' service-item d-flex ' data-aos="fade-up" data-aos-delay="400">
<ServiceCard className='m-4'      >
           
        
           <Card.Body>
           <Card.Img src={Commerce} text="Commerce"/>
             <Card.Title className='text-center'>
              Commerce
            </Card.Title>
           <Card.Text className='text-muted'>
        We try to maximise on market opportunities to make our brand competitive within the markets we ply our products and services.
        </Card.Text>
   
           </Card.Body>
         
            </ServiceCard>
</Col>
<Col sm={12} lg={4}  className=' service-item d-flex ' data-aos="fade-up" data-aos-delay="400">
<ServiceCard className='m-4'       >
           <Card.Body>
           <Card.Img src={Consulting} loading="lazy" text="Consulting" />
             <Card.Title className='text-center'>
              Consulting
            </Card.Title>
           <Card.Text className='text-muted text-left'>
    Our consulting covers the areas of research studies within the markets and providing a detailed report on the strenghts and weaknesses, and providing opportunies, risk management for business improvement.
        </Card.Text>
       
           </Card.Body>
         
            </ServiceCard>
</Col>
<div className='d-flex justify-content-end mb-3 p-4'>
<Button data-aos="fade-right" className='shadow' onClick={  ()=>{navigate("/servicedeatils")}} lg> Explore more <i><ArrowRightCircle/></i></Button>
</div>
</Row>
        </div>
</ServiceSection>

</section>

      </>
  );
}

export default Service