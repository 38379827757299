import React from "react";
    import IMG1 from '../../images/communicationFlat.jpg';
    import { CheckCircle } from "react-bootstrap-icons";
const Communication=()=>{
    return(
      <>
        <section data-aos="fade-down-right" data-aos-delay="300">
             <img src={IMG1} alt="" class="img-fluid services-img" loading="lazy"/>
            <h3> Connecting your team, improving your business with 4C Solutions.</h3>
            <p>
            Effective communication is the key to success in any business or personal relationship. 
            We pride ourselves as a multilingual group that offers the following communication services:
              </p>
            <ul>
              <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Bilingual coaching and training in English, French and Arabic.  </span></li>
              <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>We find the strengths,advantages, weaknesses and inconveniences in the marketplace both local and international.</span></li>
              <li><i class="bi bi-check-circle"> <CheckCircle/></i> <span>Wce work on improving visibility on social media platforms to rejunivinate companies websites.</span></li>
            </ul>
         
        </section>
      </>
    )
}
export default Communication;