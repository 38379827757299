import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/HomePage/Home';
import Services from './pages/Services/Services';
import Products from './pages/Products/Products';
import 'aos/dist/aos.css';
import ContactUs from './pages/ContactUS/ContactUs';
import ScrollSpy from "react-ui-scrollspy";
import PreFooter from './components/PreFooter';
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet-async';
const Landing=()=>{


    return (
    

       <>
       <Helmet>
       <title>4C Solutions</title>
       <meta name='description' content='We are registered business in Djibouti which offers a myriad of products and services ranging from: consulting, commerce, coaching and communication.' />
       </Helmet>

               <ScrollToTop/>
      
               <main id="main" className=''>
            
               <ScrollSpy  currentClassName="is-current">
               <section id='home' className=' '>
             <Home/>
           </section>
        
           <section id='services' className='  rounded border '>
             <Services/>
           </section>
           <section id='products' className='  border rounded'>
             <Products/>
           </section>
           
          <section id='contact' className='  border rounded'>
      <ContactUs/>
          </section> 
          <section>
            <PreFooter/>
          </section>
          </ScrollSpy>
          </main>
          
             
        </>
            
          
        );
}
export default Landing;